<template>
  <div class="col-md-12">
    <h4 class="course-header" style="color:#3E8147">
      <a
        v-if="course.availability == 'available'"
        style="cursor: pointer;"
        @click="onShowModal(course)"
      >
        Starting on: {{ course.formatted_start_date }}
      </a>
      <span v-if="course.availability == 'full'">
        <del>Starting on: {{ course.formatted_start_date }}</del>
      </span>
    </h4>
    <p
      class="course-description"
      v-if="course.availability == 'available'"
    >
      Dates: {{ course.formatted_dates }} <br/>
      Day(s) of Week: {{ course.formatted_days }} <br/>
      Time: {{ course.formatted_times }}
    </p>
    <a
      v-if="course.availability == 'available'"
      style="cursor: pointer;"
      @click="onShowModal(course)"
      class="btn btn-xs select-course"
    >
      Select Course
    </a>
    <img src="/templates/registration/soldout.png" style="margin-left: -18px;" v-if="course.availability == 'full'">
    <div v-if="course.availability == 'available'" style="height: 15px;"></div>
    <div v-if="course.availability == 'full'" style="height: 1px;"></div>
  </div>
</template>

<script>
  export default {
    props: ['course', 'on-show-modal'],
    data: () => ({
      pageUrl: `https://www.phlebotomyusa.com`
    }),
  }
</script>

<style>

</style>