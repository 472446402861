<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div style="height: 20px;" class="hidden-xs"></div>
        <div style="height: 5px;" class="visible-xs-block"></div>
        <h5 class="title-color-modal">
          <strong>
            I attest that I meet the admission requirements below.
          </strong>
        </h5>
        <h5 class="title-color-modal">
          <strong>
            -18 years of age or older
          </strong>
        </h5>
        <h5 class="title-color-modal">
          <strong>
            -Have a High School diploma or High School equivalency completion (must provide documentation in order to attend)
          </strong>
        </h5>
        <div style="height: 20px;" class="hidden-xs"></div>
        <div style="height: 5px;" class="visible-xs-block"></div>
        <hr>
      </div>
      <div class="col-md-6 col-xs-6">
        <button
          type="button"
          class="btn btn-modal-default"
          @click="$emit('close')"
        >
          Cancel
        </button>
      </div>
      <div class="col-md-6 col-xs-6">
        <button
          type="button"
          class="btn btn-modal-default-inverse"
          @click="onContinue"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: ['on-before-close'],
    data: () =>({
      checkoutPhlebsUrl: `https://checkout.phlebotomyusa.com`,
    }),
    computed: {
      ...mapState(['courseId'])
    },
    methods: {
      onContinue(){
        window.location.href = `${this.checkoutPhlebsUrl}?c=${this.courseId}`;
      }
    }
  }
</script>

<style>
  .title-color-modal{
    color: #2A2A2A;
    font-size: 16px;
    margin: 20px 0 20px 0;
    text-align: left;
  }

  .btn-modal-default {
    color: #53091C;
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    display: block;
    margin: 0 auto;
  }

  /* .btn-modal-default:hover{
    color:#FFFFFF;
    background-color:#901537;
    border-color:#901537
  } */

  .btn-modal-default:hover{
    color: #53091C;
  }

  .btn-modal-default-inverse {
    color: #FFFFFF;
    background-color: #901537;
    border-color: #901537;
    display: block;
    margin: 0 auto;
  }

  /* .btn-modal-default-inverse:hover{
    color:#53091C;
    background-color:#E0E0E0;
    border-color:#E0E0E0
  } */

  .btn-modal-default-inverse:hover{
    color:#FFFFFF;
  }

</style>