import axios from 'axios';
// header for post requests
const key = process.env.VUE_APP_TX_APPLICANTS_APP_API_KEY;
axios.defaults.headers.post['Header-Tx-Applicant-App'] = btoa(key);
// header for all requests
// axios.defaults.headers.common['header1'] = 'value'

const resource = `${process.env.VUE_APP_API_URL}`;

export default {
  getCityCourses: (permalink, courseType) => axios.get(`${resource}/city/${permalink}/course_type/${courseType}/sold_out`),
  processPreEnrollment: post => axios.post(`${resource}/tn_pre_registration/process`, post),
  checkStudent: post => axios.post(`${resource}/tn_pre_registration/check_student`, post),
}