export const SET_CITY = 'SET_CITY';
export const SET_COURSE_ID = 'SET_COURSE_ID';
export const SET_COURSE_TIMES = 'SET_COURSE_TIMES';
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE';
export const SET_STUDENT_INFO = 'SET_STUDENT_INFO';
export const SET_STUDENT_CARD_INFO = 'SET_STUDENT_CARD_INFO';
export const SET_STUDENT_BILLING_INFO = 'SET_STUDENT_BILLING_INFO';
export const SET_STUDENT_CARD_ERRORS = 'SET_STUDENT_CARD_ERRORS';
export const SET_STUDENT_BILLING_ERRORS = 'SET_STUDENT_BILLING_ERRORS';
export const SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE';
export const IS_PROCESSING = 'IS_PROCESSING';
export const IS_STEPPER_PROCESSING = 'IS_STEPPER_PROCESSING';
export const IS_MODAL_PROCESSING = 'IS_MODAL_PROCESSING';
export const IS_TRANSCRIPT_RECEIVED = 'IS_TRANSCRIPT_RECEIVED';