import Vue from 'vue'
import Vuex from 'vuex'
import {
  SET_CITY,
  SET_COURSE_ID,
  SET_COURSE_TIMES,
  SET_ACTION_TYPE,
  SET_STUDENT_INFO,
  SET_STUDENT_CARD_INFO,
  SET_STUDENT_BILLING_INFO,
  SET_STUDENT_CARD_ERRORS,
  SET_STUDENT_BILLING_ERRORS,
  SET_RESPONSE_MESSAGE,
  IS_STEPPER_PROCESSING,
  IS_MODAL_PROCESSING,
  IS_TRANSCRIPT_RECEIVED,
  IS_PROCESSING,
} from './mutation-types';
import RepositoriesFactory from '../repositories/RepositoriesFactory';
const coursesRepository = RepositoriesFactory.get('courses');

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isStepperProcessing: false,
    isModalProcessing: false,
    isProcessing: false,
    courseId: 0,
    applicationFee: 100,
    ecg_count: 0,
    ecgLanding: "",
    studentInfo: {
      name: process.env.NODE_ENV === `development` ? `mike` : ``,
      lastname: process.env.NODE_ENV === `development` ? `davila` :``,
      email: process.env.NODE_ENV === `development` ? `mike@mike.com` :``,
      phone: process.env.NODE_ENV === `development` ? `` :``,
      address1: process.env.NODE_ENV === `development` ? `addr1` :``,
      address2: process.env.NODE_ENV === `development` ? `` :``,
      state: process.env.NODE_ENV === `development` ? `TX` :``,
      zip: process.env.NODE_ENV === `development` ? `896523` :``,
      city: process.env.NODE_ENV === `development` ? `mcallen` :``,
      ssn: process.env.NODE_ENV === `development` ? `1234` :``,
      dob: process.env.NODE_ENV === `development` ? `` :``,
      agree: false,
      isChecklistSigned: false,
      isDisclosureSigned: false,
    },
    studentBillingAddress: {
      hasBillingAddress: false,
      address1: ``,
      address2: ``,
      state: ``,
      zip: ``,
      city: ``,
    },
    studentCardInfo: {
      cardName: process.env.NODE_ENV === `development` ? `mike davila` : ``,
      cardNumber: ``,
      runAs: `credit`,
      cardExpirationDate: ``,
      cardType: ``,
      cardCode: ``,
    },
    studentCardErrors: {
      cardNumber: ``,
      cardExpirationDate: ``,
      cardCode: ``,
      runAs: ``,
      //cardType: ``,
      cardName: ``,
    },
    studentBillingErrors: {
      hasBillingAddress: ``,
      address1: ``,
      address2: ``,
      state: ``,
      zip: ``,
      city: ``,
    },
    statesFilter: [
      { name: 'ALABAMA', abbr: 'AL'},
      { name: 'ALASKA', abbr: 'AK'},
      { name: 'AMERICAN SAMOA', abbr: 'AS'},
      { name: 'ARIZONA', abbr: 'AZ'},
      { name: 'ARKANSAS', abbr: 'AR'},
      { name: 'CALIFORNIA', abbr: 'CA'},
      { name: 'COLORADO', abbr: 'CO'},
      { name: 'CONNECTICUT', abbr: 'CT'},
      { name: 'DELAWARE', abbr: 'DE'},
      { name: 'DISTRICT OF COLUMBIA', abbr: 'DC'},
      { name: 'FEDERATED STATES OF MICRONESIA', abbr: 'FM'},
      { name: 'FLORIDA', abbr: 'FL'},
      { name: 'GEORGIA', abbr: 'GA'},
      { name: 'GUAM', abbr: 'GU'},
      { name: 'HAWAII', abbr: 'HI'},
      { name: 'IDAHO', abbr: 'ID'},
      { name: 'ILLINOIS', abbr: 'IL'},
      { name: 'INDIANA', abbr: 'IN'},
      { name: 'IOWA', abbr: 'IA'},
      { name: 'KANSAS', abbr: 'KS'},
      { name: 'KENTUCKY', abbr: 'KY'},
      { name: 'LOUISIANA', abbr: 'LA'},
      { name: 'MAINE', abbr: 'ME'},
      { name: 'MARSHALL ISLANDS', abbr: 'MH'},
      { name: 'MARYLAND', abbr: 'MD'},
      { name: 'MASSACHUSETTS', abbr: 'MA'},
      { name: 'MICHIGAN', abbr: 'MI'},
      { name: 'MINNESOTA', abbr: 'MN'},
      { name: 'MISSISSIPPI', abbr: 'MS'},
      { name: 'MISSOURI', abbr: 'MO'},
      { name: 'MONTANA', abbr: 'MT'},
      { name: 'NEBRASKA', abbr: 'NE'},
      { name: 'NEVADA', abbr: 'NV'},
      { name: 'NEW HAMPSHIRE', abbr: 'NH'},
      { name: 'NEW JERSEY', abbr: 'NJ'},
      { name: 'NEW MEXICO', abbr: 'NM'},
      { name: 'NEW YORK', abbr: 'NY'},
      { name: 'NORTH CAROLINA', abbr: 'NC'},
      { name: 'NORTH DAKOTA', abbr: 'ND'},
      { name: 'NORTHERN MARIANA ISLANDS', abbr: 'MP'},
      { name: 'OHIO', abbr: 'OH'},
      { name: 'OKLAHOMA', abbr: 'OK'},
      { name: 'OREGON', abbr: 'OR'},
      { name: 'PALAU', abbr: 'PW'},
      { name: 'PENNSYLVANIA', abbr: 'PA'},
      { name: 'PUERTO RICO', abbr: 'PR'},
      { name: 'RHODE ISLAND', abbr: 'RI'},
      { name: 'SOUTH CAROLINA', abbr: 'SC'},
      { name: 'SOUTH DAKOTA', abbr: 'SD'},
      { name: 'TENNESSEE', abbr: 'TN'},
      { name: 'TEXAS', abbr: 'TX'},
      { name: 'UTAH', abbr: 'UT'},
      { name: 'VERMONT', abbr: 'VT'},
      { name: 'VIRGIN ISLANDS', abbr: 'VI'},
      { name: 'VIRGINIA', abbr: 'VA'},
      { name: 'WASHINGTON', abbr: 'WA'},
      { name: 'WEST VIRGINIA', abbr: 'WV'},
      { name: 'WISCONSIN', abbr: 'WI'},
      { name: 'WYOMING', abbr: 'WY' }
    ],
    city: {},
    courseTimes: {
      Day: [],
      Evening: [],
      Weekend: [],
      Afternoon: [],
      Accelerated: [],
    },
    action: ``,
    responseMessage: ``,
    isTranscriptReceived: true,
  },
  mutations: {
    [SET_CITY]: (state, payload) => {
      state.city = payload;
    },
    [SET_COURSE_ID]: (state, payload) => {
      state.courseId = payload;
    },
    [SET_COURSE_TIMES]: (state, payload) => {
      for (const key in payload) {
        state.courseTimes[key] = payload[key];
      }
      //state.courseTimes = payload;
    },
    [SET_STUDENT_INFO]: (state, payload) => {
      for (const key in payload) {
        state.studentInfo[key] = payload[key];
      }
    },
    [SET_STUDENT_CARD_INFO]: (state, payload) => {
      for (const key in payload) {
        state.studentCardInfo[key] = payload[key];
      }
    },
    [SET_STUDENT_BILLING_INFO]: (state, payload) => {
      for (const key in payload) {
        state.studentBillingAddress[key] = payload[key];
      }
    },
    [SET_STUDENT_CARD_ERRORS]: (state, payload) => {
      for (const key in payload) {
        state.studentCardErrors[key] = payload[key];
      }
    },
    [SET_STUDENT_BILLING_ERRORS]: (state, payload) => {
      for (const key in payload) {
        state.studentBillingErrors[key] = payload[key];
      }
    },
    [SET_ACTION_TYPE]: (state, payload) => {
      state.action = payload;
    },
    [SET_RESPONSE_MESSAGE]: (state, payload) => {
      state.responseMessage = payload;
    },
    [IS_TRANSCRIPT_RECEIVED]: (state, payload) => {
      state.isTranscriptReceived = payload;
    },
    ['SET_ECG_COUNT']: (state, payload) => {
      state.ecg_count = payload;
    },
    [IS_PROCESSING]: (state, payload) => {
      state.isProcessing = payload;
    },
    [IS_STEPPER_PROCESSING]: (state, payload) => {
      state.isStepperProcessing = payload;
    },
    [IS_MODAL_PROCESSING]: (state, payload) => {
      state.isModalProcessing =  payload;
    },
    ['SET_ECG_LANDING']: (state, payload)=>{
      state.ecgLanding =  payload;
    }
  },
  actions: {
    async getCityCourses({commit}, { permalink, courseType }){
      try {
        commit(IS_PROCESSING, true);
        const response = await coursesRepository.getCityCourses(permalink, courseType);
        const [ data ] = response.data.cities;
        console.log(data.times.Accelerated);
        commit(SET_CITY, data.city);
        commit(SET_COURSE_TIMES, data.times);
        commit('SET_ECG_COUNT', data.ecg_count);
        commit('SET_ECG_LANDING', data.city.ecg_landing_page);
        commit(IS_PROCESSING, false);
      } catch (error) {
        commit(IS_PROCESSING, false);
        console.log(error);
      }
    },
    processPreEnrollment({ commit }, { post }){
      return coursesRepository.processPreEnrollment(post);
    },
    async checkStudent({state, commit}, { email }){
      try {
        commit(IS_MODAL_PROCESSING, true);
        const post = { email }
        const { data } = await coursesRepository.checkStudent(post);
        console.log(data);
        commit(IS_MODAL_PROCESSING, false);

        if(!data.success){
          commit(SET_RESPONSE_MESSAGE, data.msg);
        }

        else if(!data.transcript_received){
          commit(IS_TRANSCRIPT_RECEIVED, false);
        }

        else if(data.transcript_received){
          // take them for phlebotomy checkout
          window.location.href = `https://checkout.phlebotomyusa.com/?c=${state.courseId}&email=${encodeURIComponent(email)}`;
        }

      } catch (error) {
        commit(IS_MODAL_PROCESSING, false);
        commit(SET_RESPONSE_MESSAGE, `Error. Please Try Again.`);
        console.log(error);
      }
    }
  },
  modules: {
  },
  getters: {
    getIsProcessing: state => {
      return state.isProcessing
    }
  }
})
