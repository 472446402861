<template>
  <div>
    <div class="col-md-12" id="course-section" v-if="!isProcessing">
      <div class="row">
        <div class="col-md-4" v-for="(time, i) in Object.keys(courseTimes)" :key="i">
          <div v-if="courseTimes[time].length > 0">
            <div v-if="time === `Day`">
              <h4 class="course-times">
                <i class="cicon day"></i>
                Day Courses
              </h4>
              <div class="row" v-for="course, j in courseTimes[time]" :key="j">
                <Course :course="course" :on-show-modal="onShowModal" />
              </div>
            </div>
            <div v-if="time === `Afternoon`">
              <h4>
                <i class="cicon afternoon"></i>
                Afternoon Courses
              </h4>
              <div class="row" v-for="course, j in courseTimes[time]" :key="j">
                <Course :course="course" :on-show-modal="onShowModal" />
              </div>
            </div>
            <div v-if="time === `Evening`">
              <h4 class="course-times">
                <i class="cicon evening"></i>
                Evening Courses
              </h4>
              <div class="row" v-for="course, j in courseTimes[time]" :key="j">
                <Course :course="course" :on-show-modal="onShowModal" />
              </div>
            </div>
            <div v-if="time === `Weekend` && courseTimes.Afternoon.length === 0">
              <h4 class="course-times"><i class="cicon weekend"></i>
                 Weekend Courses
              </h4>
              <div class="row" v-for="course, j in courseTimes[time]" :key="j">
                <Course :course="course" :on-show-modal="onShowModal" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="courseTimes.Weekend.length > 0 && courseTimes.Afternoon.length > 0">
                <h4 class="course-times"><i class="cicon weekend"></i>
                   Weekend Courses
                </h4>
                <div class="col-md-4" style="padding-left: 0px;" v-for="course, j in courseTimes[time]" :key="j">
                  <Course :course="course" :on-show-modal="onShowModal" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else>
          Loading
        </div> -->
        </div>
        <div class="col-md-12" v-if="courseTimes.Accelerated">
          <div class="row" v-if="courseTimes.Accelerated.length > 0">
            <h4 class="course-times"><i class="fa fa-clock-o"></i> Accelerated Courses</h4>
            <div class="col-md-4" v-for="course, i in courseTimes.Accelerated" :key="i">
              <div class="row">
                <Course :course="course" :on-show-modal="onShowModal" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="display: flex; justify-content: center;">
        <p style="">Searching for classes…</p>
      </div>
      <div style="display: flex; justify-content: center; margin: 40px 0;">
        <div class="loader">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { SET_COURSE_ID/*, SET_RESPONSE_MESSAGE, IS_TRANSCRIPT_RECEIVED */ } from '../store/mutation-types';
import Course from './Course';
import RegistrationTNModal from './ModalTemplate';

export default {
  components: {
    Course,
    RegistrationTNModal,
  },
  data: () => ({
    checkoutPhlebsUrl: `https://checkout.phlebotomyusa.com`,
  }),
  computed: {
    ...mapState(['courseTimes', 'isProcessing'])
  },
  methods: {
    ...mapMutations([SET_COURSE_ID/*, SET_RESPONSE_MESSAGE, IS_TRANSCRIPT_RECEIVED*/]),
    ...mapActions(['getCourse']),
    onShowModal(course) {

      // if (typeof gradDocRequired !== 'undefined' && gradDocRequired === false) {
        window.location.href = `${this.checkoutPhlebsUrl}?c=${course.id}`;
        return;
      // }
      // this[SET_COURSE_ID](course.id);
      // this.$modal.show(
      //   RegistrationTNModal,
      //   {},
      //   {
      //     adaptive: true,
      //     //height: `100%`,
      //   },
      //   {
      //     'before-close': event => {
      //       //RegistrationTNModal.data().email = ``;
      //       this[SET_COURSE_ID](0);
      //       //this[SET_RESPONSE_MESSAGE](``);
      //       //this[IS_TRANSCRIPT_RECEIVED](true);
      //     }
      //   });
      // try {
      //   console.log(course.id);
      //   await this.getCourse({ courseId: course.id });
      //   this.$modal.show(RegistrationTNModal,{name: `example`});
      // } catch (error) {
      //   console.log(error);
      // }

    }
  }
}
</script>

<style>
.loader {
  /* Add your desired styles for the loader container here */
  position: relative;
  width: 50px;
  height: 50px;
}

.spinner {
  /* Add your desired styles for the spinner here */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ef4444;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>